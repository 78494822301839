const educationData = [
  {
    title: "Master's of Software Engineering",
    date: "2016-2019",
    location: "Western University, Canada",
  },
  {
    title: "Bachelor's of Software Engineering",
    date: "2012-2016",
    location: "Western University, Canada",
  },
]

export default educationData