const skills = [
  {
    name: "JavaScript",
    progress: 85,
  },
  {
    name: "HTML",
    progress: 70,
  },
  {
    name: "CSS",
    progress: 70,
  },
  {
    name: "Python",
    progress: 80,
  },
  {
    name: "C++",
    progress: 40,
  },
  {
    name: "Java",
    progress: 50,
  },
  {
    name: "Image Processing",
    progress: 55,
  },
  {
    name: "Deep Learning",
    progress: 55,
  },
]

export default skills