const adventuresData = [
  {
    "title": "1",
    "url": require("./cant_see.jpg"),
  },
  {
    "title": "2",
    "url": require("./china_tower.jpg"),
  },
  {
    "title": "3",
    "url": require("./dog.jpg"),
  },
  {
    "title": "4",
    "url": require("./friends_in_china.jpg"),
  },
  {
    "title": "5",
    "url": require("./great_wall_of_china.jpg"),
  },
  {
    "title": "6",
    "url": require("./icy_drive.jpg"),
  },
  {
    "title": "7",
    "url": require("./new_case.jpg"),
  },
  {
    "title": "8",
    "url": require("./shanghai_tower.jpg"),
  },
  {
    "title": "9",
    "url": require("./sleepy_head.jpg"),
  },
  {
    "title": "10",
    "url": require("./smirky_dog.jpg"),
  },
  {
    "title": "11",
    "url": require("./warriors.jpg"),
  },
  {
    "title": "12",
    "url": require("./zen_garden.jpg"),
  },

]
export default adventuresData